// src/chainId.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["GOERLI"] = 5] = "GOERLI";
  ChainId2[ChainId2["BSC"] = 56] = "BSC";
  ChainId2[ChainId2["BSC_TESTNET"] = 97] = "BSC_TESTNET";
  ChainId2[ChainId2["PLEXCHAIN"] = 1149] = "PLEXCHAIN";
  return ChainId2;
})(ChainId || {});

// src/chainNames.ts
var chainNames = {
  [5 /* GOERLI */]: "goerli",
  [56 /* BSC */]: "bsc",
  [97 /* BSC_TESTNET */]: "bscTestnet",
  [1149 /* PLEXCHAIN */]: "plexchain"
};
var chainNameToChainId = Object.entries(chainNames).reduce((acc, [chainId, chainName]) => {
  return {
    [chainName]: chainId,
    ...acc
  };
}, {});

// ../../node_modules/@wagmi/chains/dist/index.js
var bsc = {
  id: 56,
  name: "BNB Smart Chain",
  network: "bsc",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "BNB"
  },
  rpcUrls: {
    default: { http: ["https://rpc.ankr.com/bsc"] }
  },
  blockExplorers: {
    etherscan: { name: "BscScan", url: "https://bscscan.com" },
    default: { name: "BscScan", url: "https://bscscan.com" }
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 15921452
    }
  }
};
var bscTestnet = {
  id: 97,
  name: "Binance Smart Chain Testnet",
  network: "bsc-testnet",
  nativeCurrency: {
    decimals: 18,
    name: "BNB",
    symbol: "tBNB"
  },
  rpcUrls: {
    default: { http: ["https://bsc-testnet.public.blastapi.io"] }
  },
  blockExplorers: {
    etherscan: { name: "BscScan", url: "https://testnet.bscscan.com" },
    default: { name: "BscScan", url: "https://testnet.bscscan.com" }
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 17422483
    }
  },
  testnet: true
};
var goerli = {
  id: 5,
  network: "goerli",
  name: "Goerli",
  nativeCurrency: { name: "Goerli Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    alchemy: {
      http: ["https://eth-goerli.g.alchemy.com/v2"],
      webSocket: ["wss://eth-goerli.g.alchemy.com/v2"]
    },
    infura: {
      http: ["https://goerli.infura.io/v3"],
      webSocket: ["wss://goerli.infura.io/ws/v3"]
    },
    default: {
      http: ["https://rpc.ankr.com/eth_goerli"]
    }
  },
  blockExplorers: {
    etherscan: {
      name: "Etherscan",
      url: "https://goerli.etherscan.io"
    },
    default: {
      name: "Etherscan",
      url: "https://goerli.etherscan.io"
    }
  },
  contracts: {
    ensRegistry: {
      address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
    },
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 6507670
    }
  },
  testnet: true
};

// src/chainsConfig.ts
var plexchain = {
  id: 1149,
  name: "Plexchain",
  network: "plexchain",
  nativeCurrency: {
    decimals: 18,
    name: "Plexchain Native Token",
    symbol: "PLEX"
  },
  rpcUrls: {
    public: { http: ["https://plex-rpc.plexfinance.us/"] },
    default: { http: ["https://plex-rpc.plexfinance.us/"] }
  },
  blockExplorers: {
    default: { name: "PlexScan", url: "https://explorer.plexfinance.us" }
  },
  contracts: {
    multicall3: {
      address: "0x2210e34629E5B17B5F2D875a76098223d71F1D3E",
      blockCreated: 455863
    }
  }
};

// src/networkSelector.ts
var chainsSelector = [bsc, bscTestnet, goerli, plexchain];

// src/utils.ts
function getChainName(chainId) {
  return chainNames[chainId];
}
function getChainIdByChainName(chainName) {
  if (!chainName)
    return void 0;
  return chainNameToChainId[chainName] ?? void 0;
}

// src/subgraphs.ts
var V2_SUBGRAPHS = {
  [56 /* BSC */]: "https://api.thegraph.com/subgraphs/name/symplexialabs/plexswap"
};

export { ChainId, V2_SUBGRAPHS, bsc, bscTestnet, chainNameToChainId, chainNames, chainsSelector, getChainIdByChainName, getChainName, goerli, plexchain };
