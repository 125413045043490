import {
  getVersionUpgrade
} from "./chunk-PTCDLFLR.mjs";
import {
  __async,
  __spreadProps,
  __spreadValues
} from "./chunk-2L3ZO4UM.mjs";

// react/reducer.ts
import { createReducer } from "@reduxjs/toolkit";

// react/actions.ts
import { createAction } from "@reduxjs/toolkit";
var fetchTokenList = {
  pending: createAction("lists/fetchTokenList/pending"),
  fulfilled: createAction("lists/fetchTokenList/fulfilled"),
  rejected: createAction("lists/fetchTokenList/rejected")
};
var addList = createAction("lists/addList");
var removeList = createAction("lists/removeList");
var enableList = createAction("lists/enableList");
var disableList = createAction("lists/disableList");
var acceptListUpdate = createAction("lists/acceptListUpdate");
var rejectVersionUpdate = createAction("lists/rejectVersionUpdate");
var updateListVersion = createAction("lists/updateListVersion");

// react/reducer.ts
var NEW_LIST_STATE = {
  error: null,
  current: null,
  loadingRequestId: null,
  pendingUpdate: null
};
var createTokenListReducer = (initialState, DEFAULT_LIST_OF_LISTS2, DEFAULT_ACTIVE_LIST_URLS2) => createReducer(
  initialState,
  (builder) => builder.addCase(fetchTokenList.pending, (state, { payload: { requestId, url } }) => {
    var _a, _b, _c, _d;
    const current = (_b = (_a = state.byUrl[url]) == null ? void 0 : _a.current) != null ? _b : null;
    const pendingUpdate = (_d = (_c = state.byUrl[url]) == null ? void 0 : _c.pendingUpdate) != null ? _d : null;
    state.byUrl[url] = {
      current,
      pendingUpdate,
      loadingRequestId: requestId,
      error: null
    };
  }).addCase(fetchTokenList.fulfilled, (state, { payload: { requestId, tokenList, url } }) => {
    var _a, _b, _c;
    const current = (_a = state.byUrl[url]) == null ? void 0 : _a.current;
    const loadingRequestId = (_b = state.byUrl[url]) == null ? void 0 : _b.loadingRequestId;
    if (current) {
      const upgradeType = getVersionUpgrade(current.version, tokenList.version);
      if (upgradeType === 0 /* NONE */)
        return;
      if (loadingRequestId === null || loadingRequestId === requestId) {
        state.byUrl[url] = __spreadProps(__spreadValues({}, state.byUrl[url]), {
          loadingRequestId: null,
          error: null,
          current,
          pendingUpdate: tokenList
        });
      }
    } else {
      if (DEFAULT_ACTIVE_LIST_URLS2.includes(url)) {
        (_c = state.activeListUrls) == null ? void 0 : _c.push(url);
      }
      state.byUrl[url] = __spreadProps(__spreadValues({}, state.byUrl[url]), {
        loadingRequestId: null,
        error: null,
        current: tokenList,
        pendingUpdate: null
      });
    }
  }).addCase(fetchTokenList.rejected, (state, { payload: { url, requestId, errorMessage } }) => {
    var _a;
    if (((_a = state.byUrl[url]) == null ? void 0 : _a.loadingRequestId) !== requestId) {
      return;
    }
    state.byUrl[url] = __spreadProps(__spreadValues({}, state.byUrl[url]), {
      loadingRequestId: null,
      error: errorMessage,
      current: null,
      pendingUpdate: null
    });
  }).addCase(addList, (state, { payload: url }) => {
    if (!state.byUrl[url]) {
      state.byUrl[url] = NEW_LIST_STATE;
    }
  }).addCase(removeList, (state, { payload: url }) => {
    if (state.byUrl[url]) {
      delete state.byUrl[url];
    }
    if (state.activeListUrls && state.activeListUrls.includes(url)) {
      state.activeListUrls = state.activeListUrls.filter((u) => u !== url);
    }
  }).addCase(enableList, (state, { payload: url }) => {
    if (!state.byUrl[url]) {
      state.byUrl[url] = NEW_LIST_STATE;
    }
    if (state.activeListUrls && !state.activeListUrls.includes(url)) {
      state.activeListUrls.push(url);
    }
    if (!state.activeListUrls) {
      state.activeListUrls = [url];
    }
  }).addCase(disableList, (state, { payload: url }) => {
    if (state.activeListUrls && state.activeListUrls.includes(url)) {
      state.activeListUrls = state.activeListUrls.filter((u) => u !== url);
    }
  }).addCase(acceptListUpdate, (state, { payload: url }) => {
    var _a;
    if (!((_a = state.byUrl[url]) == null ? void 0 : _a.pendingUpdate)) {
      throw new Error("accept list update called without pending update");
    }
    state.byUrl[url] = __spreadProps(__spreadValues({}, state.byUrl[url]), {
      pendingUpdate: null,
      current: state.byUrl[url].pendingUpdate
    });
  }).addCase(updateListVersion, (state) => {
    if (!state.lastInitializedDefaultListOfLists) {
      state.byUrl = initialState.byUrl;
      state.activeListUrls = initialState.activeListUrls;
    } else if (state.lastInitializedDefaultListOfLists) {
      const lastInitializedSet = state.lastInitializedDefaultListOfLists.reduce(
        (s, l) => s.add(l),
        /* @__PURE__ */ new Set()
      );
      const newListOfListsSet = DEFAULT_LIST_OF_LISTS2.reduce((s, l) => s.add(l), /* @__PURE__ */ new Set());
      DEFAULT_LIST_OF_LISTS2.forEach((listUrl) => {
        if (!lastInitializedSet.has(listUrl)) {
          state.byUrl[listUrl] = NEW_LIST_STATE;
        }
      });
      state.lastInitializedDefaultListOfLists.forEach((listUrl) => {
        if (!newListOfListsSet.has(listUrl)) {
          delete state.byUrl[listUrl];
        }
      });
    }
    state.lastInitializedDefaultListOfLists = DEFAULT_LIST_OF_LISTS2;
    if (!state.activeListUrls) {
      state.activeListUrls = DEFAULT_ACTIVE_LIST_URLS2;
      DEFAULT_ACTIVE_LIST_URLS2.forEach((listUrl) => {
        if (!state.byUrl[listUrl]) {
          state.byUrl[listUrl] = NEW_LIST_STATE;
        }
        return true;
      });
    }
  })
);

// react/lists.ts
import { atom, useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";
import localForage from "localforage";
function noop() {
}
var noopStorage = {
  getItem: noop,
  setItem: noop,
  removeItem: noop,
  keys: [],
  getAllKeys: noop
};
var EMPTY = Symbol();
var createListsAtom = (storeName, reducer, initialState) => {
  function IndexedDBStorage(dbName) {
    if (typeof window !== "undefined") {
      const db = localForage.createInstance({
        name: dbName,
        storeName
      });
      return {
        db,
        getItem: (key) => __async(this, null, function* () {
          const value = yield db.getItem(key);
          if (value) {
            return value;
          }
          return initialState;
        }),
        setItem: (k, v) => {
          if (v === EMPTY)
            return;
          return db.setItem(k, v);
        },
        removeItem: db.removeItem,
        delayInit: true
      };
    }
    return noopStorage;
  }
  const listsStorageAtom = atomWithStorage(
    "lists",
    EMPTY,
    // @ts-ignore
    IndexedDBStorage("lists")
  );
  const defaultStateAtom = atom(
    (get) => {
      const got = get(listsStorageAtom);
      if (got === EMPTY) {
        return initialState;
      }
      return got;
    },
    (get, set, action) => {
      set(listsStorageAtom, reducer(get(defaultStateAtom), action));
    }
  );
  const isReadyAtom = atom((get) => get(listsStorageAtom) !== EMPTY);
  function useListState() {
    return useAtom(defaultStateAtom);
  }
  function useListStateReady() {
    return useAtomValue(isReadyAtom);
  }
  return {
    listsAtom: defaultStateAtom,
    useListStateReady,
    useListState
  };
};

// react/metalists.ts
var PLEXSWAP_EXTENDED = "https://metalists.plexfinance.us/plexswap-extended.json";
var COINGECKO = "https://metalists.plexfinance.us/coingecko.json";
var CMC = "https://metalists.plexfinance.us/cmc.json";
var OFFICIAL_LISTS = [PLEXSWAP_EXTENDED];
var UNSUPPORTED_LIST_URLS = [];
var WARNING_LIST_URLS = [];
var DEFAULT_LIST_OF_LISTS = [
  PLEXSWAP_EXTENDED,
  CMC,
  COINGECKO,
  ...UNSUPPORTED_LIST_URLS,
  // need to load unsupported tokens as well
  ...WARNING_LIST_URLS
];
var DEFAULT_ACTIVE_LIST_URLS = [];

// baselists/plex-default.tokenlist.json
var plex_default_tokenlist_default = {
  name: "PlexSwap Default List",
  timestamp: "2022-01-26T11:45:09Z",
  version: {
    major: 4,
    minor: 0,
    patch: 0
  },
  tags: {},
  logoURI: "https://swap.plexfinance.us/logo.png",
  keywords: ["plex", "default"],
  tokens: [
    {
      name: "WBNB Token",
      symbol: "WBNB",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      chainId: 56,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/wbnb.png"
    },
    {
      name: "BUSD Token",
      symbol: "BUSD",
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      chainId: 56,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/busd.png"
    },
    {
      name: "Ethereum Token",
      symbol: "ETH",
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      chainId: 56,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/eth.png"
    },
    {
      name: "BTCB Token",
      symbol: "BTCB",
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      chainId: 56,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/btcb.png"
    },
    {
      name: "Tether USD",
      symbol: "USDT",
      address: "0x55d398326f99059fF775485246999027B3197955",
      chainId: 56,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/usdt.png"
    },
    {
      name: "PlexSwap Farm Token",
      symbol: "WAYA",
      address: "0x32d9F70F6eF86718A51021ad269522Abf4CFFE49",
      chainId: 56,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/waya.png"
    },
    {
      name: "Symplexia Finance Token",
      symbol: "PLEX-F",
      address: "0xBB472510B1896C6992D658a7Ab69F7dF32a37b3c",
      chainId: 56,
      decimals: 9,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/plex.png"
    },
    {
      name: "PlexSwap Farm Token",
      symbol: "WAYA",
      address: "0xA2af2640A694f91632e60befc7Fc30C1b787D505",
      chainId: 97,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/waya.png"
    },
    {
      name: "BUSD Token",
      symbol: "BUSD",
      address: "0x8516Fc284AEEaa0374E66037BD2309349FF728eA",
      chainId: 97,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/busd.png"
    },
    {
      name: "WBNB Token",
      symbol: "WBNB",
      address: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
      chainId: 97,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/wbnb.png"
    },
    {
      name: "Symplexia Finance Token",
      symbol: "PLEX-F",
      address: "0xb7a3DFd16663dbA80c8f93338d1c59bA21680921",
      chainId: 97,
      decimals: 9,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/plex.png"
    },
    {
      name: "PlexSwap Farm Token",
      symbol: "WAYA",
      address: "0x2b82d6d89F5786C6A0fFab86b5F7F0532d5F19e4",
      chainId: 5,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/waya.png"
    },
    {
      name: "Wrapped Ether",
      address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
      symbol: "WETH",
      decimals: 18,
      chainId: 5,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/weth.png"
    },
    {
      name: "BUSD Token",
      symbol: "BUSD",
      address: "0xb809b9B2dc5e93CB863176Ea2D565425B03c0540",
      chainId: 5,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/busd.png"
    },
    {
      name: "Symplexia Finance Token",
      symbol: "PLEX-F",
      address: "0x6a69b32d60B862F99068Be5F7c304691e58B6F3f",
      chainId: 5,
      decimals: 9,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/plex.png"
    },
    {
      name: "PlexSwap Farm Token",
      symbol: "WAYA",
      address: "0x7589CF3115E060673FD0109fA6891464E13947A1",
      chainId: 1149,
      decimals: 18,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/waya.png"
    },
    {
      name: "Symplexia USD Coin",
      address: "0x69D1D347088e8916aC107b6679927C4EC5faB4a0",
      symbol: "USDP",
      decimals: 18,
      chainId: 1149,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/usdp.png"
    },
    {
      name: "Wrapped PLEX",
      address: "0x50245424Afc53E67Ca1AAD2C90401568C0eFf53A",
      symbol: "WPLEX",
      decimals: 18,
      chainId: 1149,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/wplex.png"
    },
    {
      name: "Symplexia Finance Token",
      symbol: "PLEX-F",
      address: "0xD0B07197d86434635fe30D69604751F3651c19a6",
      chainId: 1149,
      decimals: 9,
      logoURI: "https://swap.plexfinance.us/images/tokens/common/plex.png"
    }
  ]
};

// baselists/plex-unsupported.tokenlist.json
var plex_unsupported_tokenlist_default = {
  name: "PlexSwap Unsupported List",
  timestamp: "2021-01-05T20:47:02.923Z",
  version: {
    major: 1,
    minor: 0,
    patch: 0
  },
  tags: {},
  logoURI: "ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir",
  keywords: ["plex", "unsupported"],
  tokens: []
};

// baselists/plex-warning.tokenlist.json
var plex_warning_tokenlist_default = {
  name: "PlexSwap Warning List",
  timestamp: "2021-01-05T20:47:02.923Z",
  version: {
    major: 1,
    minor: 0,
    patch: 0
  },
  tags: {},
  logoURI: "ipfs://QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir",
  keywords: ["plex", "unsupported"],
  tokens: [
    {
      name: "Pokemoney Coin",
      symbol: "PMY",
      address: "0x32ff5b4C3B1744F0344D96fA2f87799Ed2805749",
      chainId: 56,
      decimals: 18
    }
  ]
};

// react/baselists.ts
var DEFAULT_TOKEN_LIST = plex_default_tokenlist_default;
var UNSUPPORTED_TOKEN_LIST = plex_unsupported_tokenlist_default;
var WARNING_TOKEN_LIST = plex_warning_tokenlist_default;

// react/useFetchListCallback.ts
import { nanoid } from "@reduxjs/toolkit";
import { useCallback } from "react";
function useFetchListCallback(dispatch) {
  return useCallback(
    (listUrl, sendDispatch = true) => __async(this, null, function* () {
      const requestId = nanoid();
      if (sendDispatch) {
        dispatch(fetchTokenList.pending({ requestId, url: listUrl }));
      }
      const getTokenList = (yield import("./getTokenList-D4BF4ARU.mjs")).default;
      return getTokenList(listUrl).then((tokenList) => {
        if (sendDispatch) {
          dispatch(fetchTokenList.fulfilled({ url: listUrl, tokenList, requestId }));
        }
        return tokenList;
      }).catch((error) => {
        console.error(`Failed to get list at url ${listUrl}`, error);
        if (sendDispatch) {
          dispatch(fetchTokenList.rejected({ url: listUrl, requestId, errorMessage: error.message }));
        }
        throw error;
      });
    }),
    [dispatch]
  );
}
var useFetchListCallback_default = useFetchListCallback;
export {
  DEFAULT_ACTIVE_LIST_URLS,
  DEFAULT_LIST_OF_LISTS,
  DEFAULT_TOKEN_LIST,
  NEW_LIST_STATE,
  OFFICIAL_LISTS,
  UNSUPPORTED_LIST_URLS,
  UNSUPPORTED_TOKEN_LIST,
  WARNING_LIST_URLS,
  WARNING_TOKEN_LIST,
  acceptListUpdate,
  addList,
  createListsAtom,
  createTokenListReducer,
  disableList,
  enableList,
  fetchTokenList,
  rejectVersionUpdate,
  removeList,
  updateListVersion,
  useFetchListCallback_default as useFetchListCallback
};
